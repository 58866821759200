<template>
    <div
      no-body
      :class="{'open': visible}"
      @mouseenter="collapseOpen"
      @mouseleave="collapseClose"
      style="margin-left: 0;"
    >
      <div
        class="d-flex justify-content-between align-items-center header_colapsed"
        :class="{'collapsed': !visible}"
        :aria-expanded="visible ? 'true' : 'false'"
        :aria-controls="collapseItemID"
        role="tab"
        data-toggle="collapse"
        @click="updateVisible(!visible)"
        :style="styleHeader"

      >
        <slot name="header"  class="center_container">
          <div>
            <svg class="size_svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <path d="M0 55.2V426c0 12.2 9.9 22 22 22c6.3 0 12.4-2.7 16.6-7.5L121.2 346l58.1 116.3c7.9 15.8 27.1 22.2 42.9 14.3s22.2-27.1 14.3-42.9L179.8 320H297.9c12.2 0 22.1-9.9 22.1-22.1c0-6.3-2.7-12.3-7.4-16.5L38.6 37.9C34.3 34.1 28.9 32 23.2 32C10.4 32 0 42.4 0 55.2z"/>
            </svg>
            <span class=" collapse-title hover_title" :style="{color: '#be63c7', fontSize: sizeTitle}">{{ title }}</span>
          </div>
          <svg v-if="!visible" class="size_svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
            <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/>
          </svg>
        </slot>
      </div>

      <b-collapse
        :id="collapseItemID"
        v-model="visible"
        :accordion="accordion"
        role="tabpanel"
        @show="$emit('show')"
        @hidden="$emit('hidden')"
      >
        <b-card-body style="padding-left: 0; padding-right: 0;">
          <slot />
        </b-card-body>
      </b-collapse>
    </div>
  </template>

  <script>
  import {
    BCard, BCardHeader, BCardBody, BCollapse,
  } from 'bootstrap-vue'
  import { v4 as uuidv4 } from 'uuid'

  export default {
    components: {
      BCard,
      BCardHeader,
      BCardBody,
      BCollapse,
    },
    props: {
      isVisible: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        required: true,
      },
      styleProps:{
        type:Object,
        default:null
      },
      sizeTitle:{
        type:String,
        default:'1.1rem'
      }
    },
    data() {
      return {
        visible: false,
        styleHeader:{},
        collapseItemID: '',
        openOnHover: this.$parent.hover,
      }
    },
    computed: {
      accordion() {
        return this.$parent.accordion ? `accordion-${this.$parent.collapseID}` : null
      },
    },
    created() {
      this.collapseItemID = uuidv4()
      this.visible = this.isVisible
      if(!this.styleProps){
        this.styleHeader={
          backgroundColor:'whitesmoke',
        }
      }else{
        this.styleHeader = this.styleProps
      }
    },
    methods: {
      updateVisible(val = true) {
        this.visible = val
        this.$emit('visible', val)
      },
      collapseOpen() {
        if (this.openOnHover) this.updateVisible(true)
      },
      collapseClose() {
        if (this.openOnHover) this.updateVisible(false)
      },
    },
  }
  </script>

  <style scoped>
  .collapse-title{
    font-weight: 500;
  }
  .size_svg{
    height: 1.2rem;
    color:#be63c7;
    margin-right: 4px;

  }
  .container_header{
    display: flex;
  }
  svg{
    fill: #be63c7;
  }
  .header_colapsed{
    margin-bottom: 1rem;
    padding: 0.8rem;
  }
  .header_colapsed:hover{
    cursor: pointer;

  }
  </style>
